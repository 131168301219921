import { Controller } from '@hotwired/stimulus'
import FroalaEditor from 'froala-editor'
import './froala-editor/froala-editor.plug'
import './froala-editor/froala-editor.zh_tw' 

export default class extends Controller {
  connect () {
    // console.log('editer load');
    new FroalaEditor('.froala_editor', {
      // Set custom buttons.
      toolbarButtons: {
        'moreText': {
          'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'clearFormatting']
        },
        'moreParagraph': {
          'buttons': ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'lineHeight', 'outdent', 'indent', 'quote']
        },
        'moreRich': {
          'buttons': ['insertLink', 'insertTable', /*'insertImage', 'insertVideo', 'embedly', 'insertFile',*/ 'insertHR']
        },
        'moreMisc': {
          'buttons': ['undo', 'redo', /* 'fullscreen', */ 'selectAll', /* 'html', 'help' */]
        }
      },
      language: 'zh_tw',
      linkEditButtons: ['linkEdit', 'linkRemove'],
      linkAutoPrefix: 'https://',
      heightMin: 150,
      fontSize: ['8', '9', '10', '11', '12', '14', '18', '20', '22', '24', '30', '36', '48', '60', '72', '96'],
      pastePlain: true
    });
  }
}