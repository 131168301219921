import { Controller } from "@hotwired/stimulus"
import { Swiper } from "swiper/bundle"

// Connects to data-controller="swiper"
export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    this.swiper = new Swiper(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.swiper.destroy()
    this.swiper = undefined
  }

  get defaultOptions () {
    return {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      navigation: { 
        nextEl: ".swiper-button-next", 
        prevEl: ".swiper-button-prev" 
      },
    }
  }
}
