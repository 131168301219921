import { Controller } from '@hotwired/stimulus'
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    Fancybox.bind("[data-fancybox]", {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  get defaultOptions () {
    return {}
  }
}