import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-reveal"
export default class extends Controller {
  static targets = [ "item" ]
  static values = {
    class: String,
    threshold: Number,
    rootMargin: String
  }

  initialize () {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this)
  }

  connect() {
    this.class = this.classValue || this.defaultOptions.class || 'fadeIn'
    this.threshold = this.thresholdValue || this.defaultOptions.threshold || 0.1
    this.rootMargin = this.rootMarginValue || this.defaultOptions.rootMargin || '0px'

    this.observer = new IntersectionObserver(this.intersectionObserverCallback, this.intersectionObserverOptions)
    this.itemTargets.forEach(item => this.observer.observe(item))
  }

  disconnect () {
    this.itemTargets.forEach(item => this.observer.unobserve(item))
  }

  intersectionObserverCallback(entries, observer) {
    entries.forEach(entry => {
      if (entry.intersectionRatio > this.threshold) {
        const target= entry.target
        const animate_class = target.getAttribute('data-animate-class')
        const delay = target.getAttribute('data-delay')

        if (animate_class) {
          target.classList.add(animate_class)
        } else {
          target.classList.add(this.class)
        }

        if (delay) {
          target.style.transitionDelay = delay
        }

        observer.unobserve(target)
      }
    })
  }

  get intersectionObserverOptions() {
    return {
      threshold: this.threshold,
      rootMargin: this.rootMargin
    }
  }

  get defaultOptions() {
    return {}
  }
}
