import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hero-list"
export default class extends Controller {
  static targets = [ "item" ]

  initialize() {
    this.animationDuration = 10
    this.index = -1
    this.showHeroList()

    this.itemTargets.forEach((element, index) => {
      element.style.animationDuration = `${this.animationDuration}s`
    })

    setInterval(() => {
      this.change_index()
    }, this.animationDuration * 1000 / 2)
  }

  showHeroList () {
    this.itemTargets.forEach((element, item_index) => {
      if ((item_index == this.index || item_index == this.index + 1) || (this.index == this.itemTargets.length - 1 && item_index == 0)) {
        element.classList.add("animate")
      } else {
        element.classList.remove("animate")
      }
    })
  }

  change_index () {
    if (this.index < this.itemTargets.length - 1) {
      this.index++
    } else {
      this.index = 0
    }
    this.showHeroList()
  }
}
